<template>
  <div>
    <window-header></window-header>
    <nav :nuevo="false" :editar="false" :eliminar="false" :acciones="false">
      <div style="display: flex; align-items: center;">
        <field name="codigo" placeholder="Buscar por código..." searchable width="220px" style="position: relative;"
          no-bind-field />
        <div class="field inline-label item-input" style="position: relative; margin-left: 10px;">
          <div class="item-inner"><label class="item-title item-label">Orden</label>
            <div class="item-input-wrap">
              <select placeholder="Orden" style="width: 110px; "
                @change="order = $event.target.value; orderDir = $event.target.options[$event.target.selectedIndex].getAttribute('dir'); initRemoteData('filter')">
                <option value="codigo" dir="ASC">Código asc</option>
                <option value="codigo" dir="DESC">Código desc</option>
                <option value="tarifa1" dir="ASC">Precio asc</option>
                <option value="tarifa1" dir="DESC">Precio desc</option>
                <option value="fecha_ultima_compra" dir="ASC">U.Compra asc</option>
                <option value="fecha_ultima_compra" dir="DESC">U.Compra desc</option>
              </select>
            </div>
          </div>
        </div>
        <div class="field inline-label item-input" style="position: relative; margin-left: 10px;">
          <div class="item-inner"><label class="item-title item-label">Columnas</label>
            <div class="item-input-wrap">
              <select placeholder="Orden" style="width: 60px; " @change="cols = parseInt($event.target.value)">
                <option value="5" :selected="cols == 5">5</option>
                <option value="6" :selected="cols == 6">6</option>
                <option value="7" :selected="cols == 7">7</option>
                <option value="8" :selected="cols == 8">8</option>
                <option value="9" :selected="cols == 9">9</option>
                <option value="10" :selected="cols == 10">10</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <field widget="checkbox" label="Mostrar inactivos" inline-label name="inactivos"
        style="position: relative; margin-left:  10px;" searchable no-bind-field/>
      <field widget="checkbox" label="Mostrar sin stock" inline-label name="sin_stock"
        style="position: relative; margin-left:  10px;" searchable no-bind-field/>

      <div style="margin-left: auto;">
        <button class="button button-fill" @click="onAccept(selectedItems); $emit('close')">
          <fa-icon icon="check" />
          <span>Aceptar</span>
        </button>
      </div>
    </nav>
    <section style="width: 1200px; height: 526px; margin: 10px; overflow: auto; display: flex;">
      <drag-select-container mode="add" selectorClass="rlist-item" :selectedItems="selectedItems"
        @change="selectedItems = $event" :items="items" style="height: 100%; width: 900px; border: 1px solid #ccc;">
        <!-- <div style="overflow-y: scroll; height: 100%; width: 900px" class="rlist-list" ref="rlist">
          <div v-for="item in items"> -->
        <RecycleScroller v-show="items.length" :items="items" :grid-items="cols" :itemSize="131"
          :itemSecondarySize="888 / cols" :key-field="primary" style="overflow-y: scroll; height: 100%;"
          class="rlist-list" ref="rlist">
          <template v-slot="{ item }">
            <div class="rlist-item" :class="{ 'rlist-item-selected': objSelectedItems.has(item[primary]) }"
              :vkey="item[primary]">
              <div style="display: flex; align-items: center;">
                <input type="checkbox" style="pointer-events: none;" :checked="objSelectedItems.has(item[primary])">
                <div style="text-align: right; margin-left: auto">{{ item.codigo }}</div>
              </div>
              <img :src="item.thumbnail ? item.thumbnail.imagen_thumbnail : require('./../assets/noimage.png')"
                style="width: 100%; height: 100%" :key="'recycle-img-' + item[primary]" />
            </div>
          </template>
        </RecycleScroller>
        <div v-show="!items.length">Sin resultados</div>
        <div v-show="loadingTable" style="width: 100%; text-align: center">
          <fa-icon icon="spinner" spin />
        </div>
      </drag-select-container>
      <RecycleScroller :items="selectedItems" :grid-items="2" :itemSize="163" :itemSecondarySize="270 / 2"
        :key-field="primary" style="flex: 1; overflow-y: scroll; height: 100%; padding: 0 10px;">
        <template v-slot="{ item, index }">
          <div class="rlist-item" :style="'width: 100%; cursor: default;'">
            <div style="display: flex; align-items: center;">
              <fa-icon icon="trash" style="cursor: pointer;" @click="selectedItems.splice(index, 1)" />
              <div style="text-align: right; margin-left: auto">{{ item.codigo }}</div>
            </div>
            <img :src="item.thumbnail ? item.thumbnail.imagen_thumbnail : require('./../assets/noimage.png')"
              style="width: 100%; height: 100%" />
          </div>
        </template>
      </RecycleScroller>
    </section>
    <div style="display: flex; padding: 10px;">
      {{ selectedItems.length }} seleccionados
      <span
        style="background: #ddd; min-width: 40px; text-align: center; padding: 2px; margin-left: auto; display: inline-block;">
        {{ count }}
      </span>
    </div>
  </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
import rTableMixin from "./../components/rTableMixin.vue";
import DragSelect from "./../components/DragSelect.vue";

export default {
  mixins: [WindowMixin, rFormMixin, rTableMixin],
  components: {
    "drag-select-container": DragSelect,
  },
  props: {
    initialSelectedItems: { type: Array, default: () => [] },
    fields: { type: Array, required: true },
    onAccept: { type: Function },
  },
  data: function () {
    let self = this;
    return {
      title: "Selección de artículos",
      dbAdapter: "articulo",
      primary: "codigo",
      selectedItems: this.initialSelectedItems,
      rowsPerPage: 180,
      cols: 8,
      loadItemIfOne: false,
      defaultDataSearch: {
        inactivos: 0,
        sin_stock: 0
      },
      filterConfig: {
        inactivos: res => {
          if (!parseInt(self.formData.inactivos)) res.push(['activo', '=', 1])
        },
        sin_stock: res => {
          if (!parseInt(self.formData.sin_stock)) res.push(['stock', '>', 0])
        }
      }
    };
  },
  computed: {
    objSelectedItems: function () {
      return new Map(this.selectedItems.map((obj) => [obj[this.primary], obj]));
    }
  },
  methods: {
    getRemoteData: function (clear, source = "normal") {
      var self = this;
      self.loadingTable = true;
      var params = {
        filter: self.conditions,
        limit: self.rowsPerPage == -1 ? null : self.rowsPerPage,
        offset: self.rowsPerPage == -1 ? null : self.rowsPerPage * (self.page - 1),
        page: self.page,
        order: self.order || self.primary,
        orderDir: self.orderDir,
        format: "standard",
        fields: self.fields
      };
      window.DB.getList(self.dbAdapter, params)
        .then(function (res) {
          if (!res.data.length && parseInt(res.count)) {
            self.page = self.page - 1;
            self.nomore = true;
          } else {
            if (clear) self.clearData();
            self.addData(res.data);
          }
          if (!parseInt(res.count) && source == "filter") {
            self.app.toast("No se ha encontrado ningún registro", "error");
          }
          self.count = res.count;
          if (
            res.count == 1 &&
            self.formData &&
            !self.initialFormData &&
            self.loadItemIfOne &&
            self.mode != "new" &&
            self.mode != "edit" &&
            (self.formData['ndoc'] || self.formData[self.primary])
          )
            self.loadItem(res.data[0][self.primary]);
          self.$emit("load");
        })
        .catch(function (e) {
          if (typeof e == "string") e = { error: e };
          let error = e.msg || e.error;
          self.app.toast({
            text: error,
            position: "center",
            closeTimeout: 2000,
          });
        })
        .finally(function () {
          self.loadingTable = false;
        });
    },
  }
}
</script>